<script>

import MyPagesBack from './MyPagesBack.vue';

export default {
  components: {
    MyPagesBack,
  },
  emits: ['close-modal', 'back', 'edit-address', 'edit-contact'],
  data() {
    return {
      requestPending: false,
    };
  },
  computed: {
    addressName() {
      return this.$user?.organization?.name || this.$user?.organization?.organizationName;
    },
    billingAddressLineOne() {
      return `${this.$user?.organization?.address} ${this.$user?.organization?.address2}, ${this.$user?.organization?.postCode}`;
    },
    billingAddressLineTwo() {
      return `${this.$user?.organization?.city}, ${this.$user?.organization?.country}`;
    },
    shippingAddresses() {
      return this.$user?.organization?.shippingAddresses || [];
    },
    contacts() {
      return this.$user?.organization?.contacts || [];
    },
  },
  methods: {
    closeModal() {
      this.$emit('close-modal');
    },
    onBack() {
      this.$emit('back');
    },
    getAddressLineOne(address) {
      return `${address?.address} ${address.address2}, ${address.postCode}`;
    },
    getAddressLineTwo(address) {
      return `${address?.city}, ${address.country}`;
    },
    editBillingAddress() {
      const address = {
        no: this.$user?.organization?.organizationNo,
        code: '',
        name: this.$user?.organization?.name,
        address: this.$user?.organization?.address,
        address2: this.$user?.organization?.address2,
        postCode: this.$user?.organization?.postCode,
        city: this.$user?.organization?.city,
        country: this.$user?.organization?.country,
      };

      this.$emit('edit-address', 'billing', address);
    },
    editShippingAddress(shippingAddress) {
      this.$emit('edit-address', 'shipping', shippingAddress);
    },
    addShippingAddress() {
      const shippingAddress = {
        no: this.$user?.organization?.organizationNo,
        code: '',
        systemId: '',
        name: '',
        address: '',
        address2: '',
        postCode: '',
        city: '',
        country: '',
      };

      this.$emit('edit-address', 'shipping', shippingAddress);
    },
    editContact(contact) {
      this.$emit('edit-contact', contact);
    },
    addContact() {
      const contact = {
        no: this.$user?.organization?.organizationNo,
        code: '',
        systemId: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      };

      this.$emit('edit-contact', contact);
    },
  },
};
</script>

<template>
  <div class="company-information">

    <my-pages-back
      @back="onBack()"
      @exit="closeModal()"
    />

    <div class="company-information__content">
      <div class="company-information__title">
        {{ $globalTexts.mypages__company_information }}
      </div>
      <div class="company-information__company">
        {{ $user.companyName }}
      </div>
      <div class="company-information__nav_customer_number_group">
        <div class="company-information__nav_customer_number">
            {{ $globalTexts.mypages__company_nav_customer_number }}
          </div>
        <div class="company-information__nav_customer_number_value">
            {{ $user.organization.navCustomerNumber}}
          </div>
      </div>
      <div>
        <div class="company-information__subtitle">{{ $globalTexts.orderdetails__invoice_address}}</div>
        <div class="company-information__address">
          <div class="company-information__card">
              <div class="company-information__card-title">{{ addressName }}</div>
              <div class="company-information__card-text">{{ billingAddressLineOne }}</div>
              <div class="company-information__card-text">{{ billingAddressLineTwo }}</div>
          </div>

          <!-- <button class="secondary-button" :disabled="requestPending" @click="editBillingAddress">{{ $globalTexts.global__edit }}</button> // Hidden for now instead of removed, ref VIN-78 for more info--> 
          <div class="company-information__card-text company-information__card-info">{{ $globalTexts.orderdetails__contact_us_for_changes }}</div>
        </div>
      </div>

      <div>
        <div class="company-information__subtitle">{{ $globalTexts.orderdetails__delivery_addresses}}</div>

        <div>
          <div class="company-information__address company-information__address-delivery" v-for="(shippingAddress, idx) in shippingAddresses" :key="idx">
            <div class="company-information__card">
                <div class="company-information__card-title">{{ shippingAddress.name || addressName }}</div>
                <div class="company-information__card-text">{{ getAddressLineOne(shippingAddress) }}</div>
                <div class="company-information__card-text">{{ getAddressLineTwo(shippingAddress) }}</div>
            </div>

            <button class="secondary-button" :disabled="requestPending" @click="editShippingAddress(shippingAddress)">{{ $globalTexts.global__edit }}</button>
          </div>
        </div>

        <button class="secondary-button" :disabled="requestPending" @click="addShippingAddress"> + {{ $globalTexts.mypages__new_address }}</button>
      </div>

      <div>
        <div class="company-information__subtitle">{{ $globalTexts.mypages__contacts}}</div>

        <div>
          <div class="company-information__contact" v-for="(contact, idx) in contacts" :key="idx">
            <div class="company-information__card">
                <div class="company-information__card-title">{{ contact.firstName }} {{ contact.lastName }}</div>
                <div class="company-information__card-text">{{ contact.email }}</div>
                <div class="company-information__card-text">{{ contact.phone }}</div>
            </div>

            <button class="secondary-button" :disabled="requestPending" @click="editContact(contact)">{{ $globalTexts.global__edit }}</button>
          </div>
        </div>

        <button class="secondary-button company-information__add-contact" :disabled="requestPending" @click="addContact"> + {{ $globalTexts.mypages__new_contact }}</button>
      </div>

    </div>
  </div>
</template>

<style>
  .company-information {
    height: auto;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  .company-information__content {
    padding: 0px 1rem 1rem;
    height: 0;
  }

  .company-information__title {
    font-family: Mulish;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: var(--color-black);
  }

  .company-information__subtitle {
    font-family: Mulish;
    font-weight: 400;
    font-size: 20px;
    line-height: 35px;
    color: #1A1A1A;
    margin: 14px 0;
  }

  .company-information__company {
    font-family: Mulish;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1A1A1A;
    margin-top: 8px;
  }

  .company-information__nav_customer_number_group {
    display: flex;
  }

  .company-information__nav_customer_number {
    font-size: 14px;
    line-height: 26px;
    color: #686868;
  }

  .company-information__nav_customer_number_value {
    font-size: 14px;
    line-height: 26px;
    color: #686868;
    padding-left: 5px;
  }

  .company-information__address {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .company-information__address-delivery {
    padding: 18px 0;
    border-bottom: 0.5px solid #DADADA;
  }

  .company-information__address-delivery:first-child {
    padding-top: 0;
  }

  .company-information__address-delivery:last-child {
    border-bottom: none;
  }

  .company-information__contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 0;
    border-bottom: 0.5px solid #DADADA;
  }

  .company-information__contact:first-child {
    padding-top: 0;
  }

  .company-information__contact:last-child {
    border-bottom: none;
  }

  .company-information__card {
    margin-right: 14px;
  }

  .company-information__card-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
    color: #282828;
  }

  .company-information__card-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #686868;
  }

  .company-information__card-info{
    max-width: 100px;
    margin-right: 1rem;
    text-align: center;
  }

  .company-information__add-contact {
    margin-bottom: 2rem;
  }
</style>
