import { computed } from 'vue';
import useContext from '@/composables/useContext';

export default function () {
  const { user } = useContext();

  const organization = computed(() => {
    return user.value?.organization;
  });

  const organizationName = computed(() => {
    return user.value?.organization?.organizationName;
  });

  const hasMultipleOrganizations = computed(() => {
    return user.value?.isAuthenticated && user.value?.organizations?.length > 1;
  });

  return {
    organizationName,
    hasMultipleOrganizations,
    organization,
  };
}
