<script>
import { defineComponent, inject, watch } from 'vue';
import Dropdown from "../forms/Dropdown.vue";
import RadioButton from '../forms/RadioButton.vue';

import useWebsiteTexts from '@/composables/useWebsiteTexts';

import ConfiguratorChevron from '../ProductConfigurator/ConfiguratorChevron.vue';
import { configuratorStateKey } from '../ProductConfigurator/ProductConfiguratorKeys';

export default defineComponent({
    components: {
        ConfiguratorChevron,
        Dropdown,
        RadioButton,
    },
    inject: ["$addToCartContextReactive"],
    emits: ["changeRecipientCountry"],
    setup() {
        const { websiteText } = useWebsiteTexts();
        const { configuratorState } = inject(configuratorStateKey);
        return { websiteText, configuratorState };
    },
    props: {
        variants: {
            type: Array,
            required: true,
        }
    },
    data() {
        return {
            toggleState: false,
        };
    },
    computed: {
        priorityCountries() {
            const countries = this.$channel.recipientCountries;
            if (!countries || countries === null || countries.length === 0) return [];
            return countries.filter(
               c => c.priority === "VingaMarket").sort(
                   (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        },
        otherCountries() {
            const countries = this.$channel.recipientCountries;
            if (!countries || countries === null || countries.length === 0) return [];
            return countries.filter(
                c => c.priority === "OtherMarket").sort(
                    (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        },
        currentCountry() {
            if (!this.configuratorState.recipientCountry || !this.configuratorState.recipientCountry.name) {
                return [];
            }
            return [{ name: this.configuratorState.recipientCountry.name }];
        },
        channelCountry() {
            return this.$cart.countries ? [{ name: this.$cart?.countries[0].text }] : [];
        },
        getOrganisationCountry() {
            const country = (this.$channel.recipientCountries?.filter(c => c.id === this.$user?.organization?.country)[0])?.name;
            return country? [{ name: country }] : []
        },
        errorMessage(){
            const orgCountry = this.getOrganisationCountry.length > 0 ? this.getOrganisationCountry[0]?.name : this.channelCountry[0]?.name;
            const allCountries = this.getAllCountriesAlternativeSorted();
            const isDifferentCountry = allCountries.length > 0 && orgCountry != allCountries[0];
            return  isDifferentCountry ? this.$globalTexts.giftcard__warning_different_recipient_country : "";
        }
    },
    watch: {
        getOrganisationCountry() { 
            const allCountries = this.getAllCountriesAlternativeSorted(); 
            if (!this.configuratorState.recipientCountry?.name && allCountries.length > 0) {
                this.onSelectCountry(allCountries[0])
            }
        },
    },
    methods: {
        getAllCountriesSorted(){ // Leaving this one here for the future, recipient countries will need a rework, but not all options are supported right now in nav.
            return Array.from(new Set(
                this.currentCountry
                .concat(this.getOrganisationCountry)
                .concat(this.channelCountry)
                .concat(this.priorityCountries)
                .concat(this.otherCountries)
                .map(c => c.name)));
        },
        getAllCountriesAlternativeSorted(){
            if(this.$user?.isAuthenticated && this.getOrganisationCountry.length > 0){
                return this.getOrganisationCountry.map(c => c.name);
            }
            return this.channelCountry.map(c => c.name);
        },
        onSelectCountry(selected) {
            var countryId = this.$channel.recipientCountries?.filter(c => c.name === selected)[0]?.id;
            this.$emit('changeRecipientCountry', {id: countryId, name: selected});
        }
    },
    mounted(){
        this.onSelectCountry(this.getAllCountriesAlternativeSorted()[0]);
    }
});
</script>

<template>
    <div class="recipient-country-selector">
        <!-- <dropdown //Left this for the same reason as above, will probably be used again later
            :options="getAllCountriesAlternativeSorted()"
            class="channel-select__form-item"
            :label="$globalTexts.giftcard__select_recipient_country"
            :error-message="errorMessage"
            @change="onSelectCountry"
            ><template v-slot:selected="slotProps">
                  <div class="channel-select__selected">
                    {{ getAllCountriesAlternativeSorted()[0] }}
                  </div>
                </template>
                <template v-slot:option="slotProps">
                  <div class="channel-select__option">
                    <div>{{ slotProps.option }}</div>
                  </div>
                </template>
        </dropdown> -->
        <radio-button
            name="deliveryCountry"
            :text="$globalTexts.giftcard__select_recipient_country"
            :description="getAllCountriesAlternativeSorted()[0]"
            :modelValue="true" 
        />
    </div>
</template>

<style>
.recipient-country-selector {
    display:block
}
</style>
