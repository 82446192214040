<script>
import { useCartFunctions } from '../helpers/cart-actions';

export default {
  setup() {
    return { ...useCartFunctions() };
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
    campaignPercentage: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    isMounted: false,
  }),
  computed: {
    giftCardShippingDate() {
      return this.row?.giftCardShippingDate;
    },
    articleNumber() {
      return this.row.articleNumber || '';
    },
    variantType() {
      let variant = '';
      if (this.row.color && this.row.size) {
        variant += this.row.size
          ? `${this.row.color}, ${this.row.size}`
          : this.row.color;
      } else if (this.row.size) {
        variant += this.row.size || '';
      }
      else {
        variant = this.row.variantName;
      }
      return variant;
    },
    quantity() {
      return this.row.quantity;
    },
    stock() {
      return this.row.stock || {};
    },
    currentStock() {
      if (this.giftCardInput) {
        const date = new Date(this.row?.giftCardShippingDate);
        const stockLabel = this.$formatDate(date, 'yyyy-MM-dd');
        const label = this.$replaceTokens(
          this.$globalTexts.global__available_date,
          {
            date: stockLabel,
          }
        );
        const stock = {
          hasInfiniteStock: true,
          stockLabel: label,
          deliveryLabel: label,
        };
        return stock;
      }
      const stock = {
        ...this.stock.currentStock,
        stockLabel: this.$globalTexts.global__in_stock,
        deliveryLabel: this.$globalTexts.global__available_today,
      };
      return stock;
    },
    incomingStockItems() {
      const stock = (this.stock?.incomingStock || []).map((s) => {
        const date = new Date(s.date);
        s.stockLabel = this.$formatDate(date, 'yyyy-MM-dd');

        s.deliveryLabel = this.$replaceTokens(
          this.$globalTexts.global__available_date,
          {
            date: s.stockLabel,
          }
        );

        return s;
      });
      return stock;
    },
    allStockItems() {
      const stockItems = [...this.incomingStockItems] || [];

      if (this.currentStock) {
        stockItems.unshift(this.currentStock);
      }
      stockItems.forEach((s) => (s.isActive = false));
      stockItems.sort((a, b) => (a.date > b.date) - (a.date < b.date));

      return stockItems;
    },
    deliveryLabel() {
      const stockItem = this.allStockItems.find(
        (s) => s.amount >= this.quantity || s.hasInfiniteStock
      );
      return stockItem?.deliveryLabel;
    },
    giftCardInput() {
      return this.row.giftCardInput;
    },
  },
  methods: {
    getProductImageUrl(row) {
      const imageHeight =
        this.$refs?.imageContainer?.getBoundingClientRect()?.height || 300;

      const imageId = row.giftCardImageId || row.imageId;

      return this.$toLitiumMediaUrl(imageId, { maxHeight: imageHeight }) || '';
    },
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<template>
  <div class="cart-item">
    <router-link :to="row.url" class="cart-item__thumbnail-container">
      <div class="cart-item__variant-image-overlay"></div>
      <img
        v-if="isMounted"
        :src="getProductImageUrl(row)"
        alt="product image"
        loading="lazy"
        class="cart-item__variant-image"
      />
    </router-link>
    <div class="cart-item__details">
      <div class="cart-item__product-name-and-number">
        <div class="cart-item__product-name">
          {{ variantType }}
        </div>
        <div
          v-if="campaignPercentage"
          class="cart-item__campaign"
        >
          (-{{campaignPercentage}}%)
        </div>
        <div v-if="articleNumber" class="cart-item__details-spacer"></div>
        <div class="cart-item__article-number">
          {{ articleNumber }}
        </div>
      </div>
      <div class="cart-item__delivery-info">
        {{ deliveryLabel }}
      </div>
      <div class="cart-item__quantity-amount" >
        {{ quantity }} {{$globalTexts.productdetailspage_printpieceabbreviated}}
      </div>
    </div>
  </div>
</template>

<style>
.cart-item {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  position: relative;
}

.cart-item__thumbnail-container {
  position: relative;
  width: 42px;
  height: 56px;
  user-select: none;
}

.cart-item__thumbnail {
  padding-top: 130%;
}

.cart-item__image-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.8;
  background-color: #efefef;
  mix-blend-mode: darken;
}

.cart-item__variant-image {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  margin: auto;
}

.cart-item__variant-image-overlay {
  z-index: 10;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.8;
  background-color: #efefef;
  mix-blend-mode: darken;
}

.cart-item__details {
  padding-left: 10px;
}

.cart-item__product-name-and-number {
  font-size: 12px;
  letter-spacing: 0.18px;
  line-height: 17px;
  color: var(--color-text-darker);
  display: flex;
  align-items: center;
}

.cart-item__product-name {
  font-weight: 700;
}

.cart-item__campaign {
  color: var(--color-feedback-error);
  margin-left: 4px;
}

.cart-item__details-spacer {
  margin: 0 6px;
  height: 12px;
  border-left: 1px solid #E5E5E5;
}

.cart-item__delivery-info {
  font-size: 12px;
  line-height: 17px;
  color: var(--color-neutrals-80);
}

.cart-item__quantity-amount {
  font-size: 13px;
  line-height: 22px;
}
</style>
