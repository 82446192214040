<script>
export default {
  inject: {
    $giftCardStateReactive: '$giftCardStateReactive',
  },
  computed: {
    title() {
      return (
        this.$giftCardStateReactive?.giftCardTitle ||
        this.$globalTexts.giftcard__text_title || 'giftcard__text_title'
      );
    },
    message() {
      return (
        this.$giftCardStateReactive?.giftCardDescription ||
        this.$globalTexts.giftcard__personal_message || 'giftcard__personal_message'
      );
    },
    logoTypeUrl() {
      return this.$toLitiumMediaUrl(this.logoTypeId || '', { maxWidth: 135 });
    },
    logoTypeId() {
      return this.$giftCardStateReactive?.giftCardLogoTypeSystemId || '';
    },
    insideCoverImageUrl() {
      return this.$toLitiumMediaUrl(this.insideCoverImage || '', {
        maxWidth: 436,
      });
    },
    insideCoverImage() {
      return this.$giftCardStateReactive?.insideCoverImageSystemId || '';
    },
  },
};
</script>

<template>
  <div
    class="gift-card-preview"
    :class="{ 'gift-card-preview__with-border': !insideCoverImage }"
  >
    <div class="gift-card-preview__text-page">
      <h3 class="gift-card-preview__title">
        {{ title }}
      </h3>
      <div class="gift-card-preview__message-logotype-container">
        <p class="gift-card-preview__message">
          {{ message }}
        </p>
        <div class="gift-card-preview__logotype-container">
          <div class="gift-card-preview__logotype-spacer"></div>
          <div v-if="!logoTypeId" class="gift-card-preview__logotype-placeholder">
            {{ $globalTexts.giftcard__logotype || 'giftcard__logotype' }}
          </div>
          <img
            v-if="logoTypeId"
            class="gift-card-preview__logotype"
            :src="logoTypeUrl"
          />
        </div>
      </div>
    </div>
    <div class="gift-card-preview__images-page">
      <img
        v-if="insideCoverImage"
        class="gift-card-preview__content-image"
        :src="insideCoverImageUrl"
      />
    </div>
  </div>
</template>

<style>
.gift-card-preview {
  display: flex;
  background: #fff;
}

.gift-card-preview__text-page {
  flex-basis: 50%;
  height: 100%;
  padding: 17% 40px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow: hidden;
  position: relative;
  justify-content: flex-end;
}

.gift-card-preview__with-border
.gift-card-preview__text-page::after {
  position: absolute;
  content: '';
  inset: 0 -6px 0 0;
  border-right: 7px dashed var(--color-neutrals-55);
}

.gift-card-preview__title {
  color: var(--color-black);
  margin: 0 0 8px;
  white-space: pre-wrap;
}

.gift-card-preview__message-logotype-container {
  height: 84%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding-bottom: 15px;
}

.gift-card-preview__message {
  color: var(--color-black);
  font-size: 18px;
  white-space: pre-wrap;
}

.gift-card-preview__logotype-container {
  width: 150px;
  max-width: 100%;
  margin: auto auto 0;
  position: relative;
}

.gift-card-preview__logotype-spacer {
  padding-top: 50%;
}

.gift-card-preview__logotype-placeholder {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  display: grid;
  place-content: center;
  overflow: hidden;
}

.gift-card-preview__logotype-placeholder::after {
  position: absolute;
  content: '';
  inset: -6px;
  border: 7px dashed var(--color-neutrals-55);
}

.gift-card-preview__logotype {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.gift-card-preview__images-page {
  width: 50%;
  background: var(--color-neutrals-45);
  overflow: hidden;
  position: relative;
}

.gift-card-preview__content-image {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

@media (--phoneAndTablet) {
  .gift-card-preview__text-page {
    padding: 17% 20px 20px;
  }

  .gift-card-preview__title {
    font-size: 12px;
  }

  .gift-card-preview__message {
    font-size: 10px;
  }

  .gift-card-preview__logotype-container {
    width: 65px;
  }

  .gift-card-preview__logotype-spacer {
    padding-top: 40%;
  }

  .gift-card-preview__logotype-placeholder {
    font-size: 8px;
  }

  .gift-card-preview__text-page {
    padding: 17% 20px 20px;
  }
}
</style>
