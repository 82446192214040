import { computed, inject, Ref, ref } from 'vue';
import useContext from '@/composables/useContext';

const tokenRegex = /\{{(.+?)\}}/gi;

function replace(
  textToFormat: string,
  websiteTexts: Ref<any>,
  tokens?: { [key: string]: string | number }
) {
  if (!tokens) {
    tokens = {};
  }

  return textToFormat.replace(tokenRegex, (_, p1: string) => {
    if (tokens) {
      const token = tokens[p1.toLowerCase()];
      if (typeof token !== 'undefined') {
        return token.toString();
      }
    }

    const token = websiteTexts.value?.[p1.toLowerCase()];
    if (typeof token !== 'undefined') {
      return token.toString();
    }

    return `{${p1.toLowerCase()}}`;
  });
}

export default function () {
  const { channel } = useContext();
  const websiteTexts = computed(() => {
    return channel.value?.website?.texts || {};
  });

  return {
    websiteText: (key: string, tokens?: { [key: string]: string | number }) =>
      computed<string>(() =>
        replace(websiteTexts.value?.[key] || key, websiteTexts, tokens)
      ),
  };
}
