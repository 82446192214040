import * as config from '@/config';

const printessBaseUrl = "https://api.printess.com/production";

export function getAddonTitle(addon){
    let title = addon.name;
    if(!!addon.extraInfo){
      const info = JSON.parse(addon.extraInfo);
      let infoTitle = info?.Name;
      if(!!infoTitle){
        title += ":\n" + infoTitle;
      }
    }
    return title;
}

function getRequestContext(channel){
    return {
        channelSystemId: channel?.systemId,
        websiteSystemId: channel?.website?.systemId,
        culture: channel?.locale,
    };
}

async function placeRequest(channel, body, method) {
    const requestContext = getRequestContext(channel);
     return await fetch(`${config.litiumBaseUrl}/api/printess/${method}`, {
        method: 'POST',
        headers: {
            'litium-request-context': JSON.stringify(requestContext),
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    });
}

export async function fetchDesignsNav(channel, organizationName) {
    const body = {
        CustomerNo: organizationName,
        Repository: "Voucher",
    };
    return await placeRequest(channel, body, "list-designs").then((response) => {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response.json().then(body => {
            if(body.Data.Designs.length > 0){
                return body.Data.Designs;
            } else{
                return [];
            }
        })
    });
}

export async function deleteDesignNav(channel, organizationName, externalId){
    const body = {
        CustomerNo: organizationName,
        ExternalId: externalId,
        Repository: "Voucher",
    };
    return await placeRequest(channel, body, "delete-design").then((response) => {
        if (!response.ok) {
            throw Error(response.statusText);
        }
    })
}

export async function saveDesignNav(channel, organizationName, title, action, templateNo, newDesign){
    const body = {
        CustomerNo: organizationName,
        TemplateNo: templateNo,
        Name: title,
        Action: action,
        ThumbnailURL: newDesign?.thumbnailUrl,
        ExternalId: newDesign?.token,
        Repository: "Voucher",
    };
    return await placeRequest(channel, body, "save-design").then((response) => {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return body;
    })
}

export function createPrintessInfo(design) {
    if (design == null) return null;
    var info = {
        SaveToken: design.ExternalId,
        Name: design.Name,
        ThumbnailUrl: design.ThumbnailURL,
        DesignType: "PDF",
    }
    var infoString = JSON.stringify(info);
    return infoString;
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

async function postJson(url, model) {
    return await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + process.env.PRINTESS_SERVICE_TOKEN
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(model)
    });
}

async function sendToProduction(token) {
    const model = {
        "templateName": token || "Vinga-GiftCard",
        "origin": "Vinga",
        "outputSettings": {
            "dpi": 300
        }
    };
    const response = await postJson(printessBaseUrl + "/produce", model);
    return response;
}

async function getStatus(jobId) {
    const model = { "jobId": jobId };
    const response = await postJson(printessBaseUrl + "/status/get", model);
    if (!response.ok) {
        throw new Error(`Cannot retrieve status: ${response.statusText} (statusCode=${response.status})`);
    }
    const apiStatus = await response.json();
    return apiStatus;
}

export async function fetchPdf(rowId, addonId, info, callback) {
    const produceResponse = await sendToProduction(info.SaveToken);
    if (!produceResponse.ok) {
        const errorMessage = await produceResponse.text();
        throw new Error("Cannot produce pdf error=" + errorMessage + ", statusCode=" + produceResponse.status);
    }

    const produceJson = await produceResponse.json();
    const jobId = produceJson.jobId;
    const statusModel = { "jobId": jobId };

    let isFinished = false;
    let status;

    do {
        status = await getStatus(jobId);
        isFinished = status.isFinalStatus;

        if (!isFinished) {
            await sleep(1000);
        }
    } while (!isFinished);

    if (status.isSuccess) {
        const pdfs = Object.keys(status.result.r).map(key => ({ document: key, url: status.result.r[key] }));

        for (const document of pdfs) {
            const blob = await window.fetch(document.url).then(r => r.blob());
            const result = await convertBlobToBase64(blob);
            await callback(result, rowId, addonId, info);
        }
    } else {
        alert("Cannot render pdf: " + status.errorDetails);
    }
}

const convertBlobToBase64 = (blob) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = (event) => resolve(event.target.result);
  reader.onerror = reject;
  reader.readAsDataURL(blob);
});