<script>
import { fetchPageBuilder } from '@drapejs/core'
import * as user from '../../../connectors/litium/user.ts'

import Dropdown from '../forms/Dropdown.vue'

export default {
  components: {
    Dropdown,
  },
  data: () => ({
    currentOrganizationId: '',
    organizations: []
  }),
  methods: {
    setOrganizations(organizations) {
      this.organizations = [...(organizations || [])].map((o) => {
        const r = {...o};
        r.value = o.organizationId
        r.text = o.organizationName
        return r;
      })
    },
    async changeOrganization(organization) {
      if(!organization?.organizationId) {
        return;
      }

      const request = fetchPageBuilder(
        this.$route.protocol,
        this.$route.host,
        this.$route.pathname,
        {
          ...this.$route.query,
        },
        '',
      )
      request.organizationId = organization.organizationId;

      const result = await this.$invoke(
        user.commands.changeOrganization,
        request,
      )

      switch (result.error) {
        case 'NONE':
          this.$refs?.dropdown?.shrink();
          window.location.reload();
          break
      }
    },
    async searchHandler(searchPhrase) {
      if (!searchPhrase) {
        this.setOrganizations(this.$user?.organizations)
      } else {
        const result = await this.$invoke(user.commands.searchOrganizations, {
          url: location.href,
          searchPhrase,
        });
        console.warn(result.organizations);
        this.setOrganizations(result.organizations);
      }
    },
  },
  created() {
    this.setOrganizations(this.$user?.organizations);

    if(this.$user?.organization?.organizationId){
      this.currentOrganizationId = this.$user.organization.organizationId;
    }
    
  },
}
</script>

<template>
  <label>{{ $globalTexts.mypages__shop_as }}</label>
  <dropdown
    @change="changeOrganization"
    v-model="currentOrganizationId"
    :options="organizations"
    :searchHandler="searchHandler"
    :shrink-on-select="false"
    class="my-pages-menu-organization-switcher"
    ref="dropdown"
    :search-placeholder="$globalTexts.mypages__search_organization"
    :enable-search="true"
  />
</template>
