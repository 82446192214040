<script>
import useContext from '@/composables/useContext';

export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
    hideRelatedArticles: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { isAuthorized, channel } = useContext();

    return { isAuthorized, channel }
  },
  data: () => ({
    compareProduct: false,
    selectedColor: undefined,
    isMounted: false,
  }),
  computed: {
    currentVariant() {
      const variants = this.product?.variants || [];
      for (const variant of variants) {
        if (
          variant.articleNumber.toLowerCase()
          === this.product.articleNumber.toLowerCase()
        ) {
          return variant;
        }
      }

      return null;
    },
    variantQueryParams() {
      let urlSuffix = '';
      if (this.currentVariant) {
        const suffixParts = [
          {
            key: 'size',
            value: this.currentVariant?.size || '',
          },
          {
            key: 'color',
            value: (this.currentVariant?.color || []).join(','),
          },
        ].filter((q) => q.value);

        if (suffixParts.length) {
          urlSuffix = suffixParts
            .reduce((p, { key, value }) => `${p}${key}=${value}&`, '?')
            .slice(0, -1);
        }
      }
      return urlSuffix;
    },
    productUrl() {
      return (
        (this.product?.relativeURL || this.product?.url || this.product.href)
          + this.variantQueryParams || ''
      );
    },
    productBadge() {
      return this.product?.blob || '';
    },
    productName() {
      return this.product?.productName || this.product?.name || '';
    },
    currentPrice() {
      let price = this.product.campaignPrice && this.product.campaignPrice < this.product.price
        ? this.product.campaignPrice
        : this.product.price;
        if(!this.isAuthorized() && this.channel?.combineGiftCardPriceWithShipping && this.product.isGiftCard){
          price += this.product?.giftCardFreightPrice || 0;
        };
        return price;
    },
    originalPrice() {
      if (this.product.originalPrice > this.currentPrice) {
        return this.product.originalPrice;
      }

      return 0;
    },
    articleNumber() {
      return this.product?.articleNumber || '';
    },
    printAvailable() {
      return this.product?.printAvailable || false;
    },
    comingSoon() {
      return this.product?.comingSoon || false;
    },
    common_printavailable() {
      return 'Print available';
    },
    common_comingsoon() {
      return 'Coming soon';
    },
    currency() {
      return this.$cart?.currency?.id;
    },
    locale() {
      return this.$channel?.locale;
    },
    decimals() {
      return this.$cart?.currency?.currencyDecimals || 0;
    },
    image() {
      const height = this.$refs?.imageContainer?.getBoundingClientRect()?.height || 300;
      return this.$toLitiumMediaUrl(
        this.product.image || this.product.imageId,
        { maxHeight: height },
      );
    },
    variants() {
      return this.product?.variants || [];
    },
    otherVariants() {
      return this.variants.filter((v) => v.articleNumber != this.articleNumber);
    },
    hasMoreInfo() {
      return this.printAvailable;
    },
    category() {
      return this.$page.dataJson?.category;
    },
    hidePrices() {
      const hidePrices = this.$channel?.hidePricesForAnonymousUsers || false;
      return hidePrices ? !this.$user?.isAuthenticated : false;
    },
  },
  methods: {
    getProductImageUrl() {
      const height = this.$refs?.imageContainer?.getBoundingClientRect()?.height ?? 300;
      return this.$toLitiumMediaUrl(this.product.image, { maxHeight: height });
    },
    pushSelectPromotionEvent() {
      if (typeof dataLayer === 'undefined' || !this.category) {
        return;
      }

      if(!(this.product.campaignPrice && this.product.campaignPrice < this.product.price)){
        return;
      }

      const dataToPush = {
        event: 'select_promotion',
        items: [
          {
            item_id: this.articleNumber,
            item_name: this.product?.googleAnalyticsName || this.productName,
          },
        ],
      };

      dataLayer.push(dataToPush);
    },
    pushSelectItemEvent() {
      if (typeof dataLayer === 'undefined' || !this.category) {
        return;
      }

      const dataToPush = {
        event: 'select_item',
        items: [
          {
            item_id: this.articleNumber,
            item_name: this.product?.googleAnalyticsName || this.productName,
            item_list_id: `category_${this.category.systemId}`,
            item_list_name: `Category: ${this.category.googleAnalyticsName}`,
          },
        ],
      };

      dataLayer.push(dataToPush);
    },
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<template>
  <router-link
    :to="productUrl"
    class="product-card"
    @click="pushSelectItemEvent()"
  >
    <div class="product-card__content">
      <div class="product-card__product-media">
        <div class="product-card__image-container">
          <div class="product-card__product-badge-container">
            <div class="product-card__product-badge" v-if="productBadge">
              {{ productBadge }}
            </div>
          </div>
          <div
            class="product-card__product-incoming-container"
            v-if="comingSoon"
          >
            <div class="product-card__product-incoming">
              {{ common_comingsoon }}
            </div>
          </div>
          <div class="product-card__image-spacer" ref="imageContainer"></div>
          <div class="product-card__image-overlay"></div>
          <img
            v-if="isMounted"
            :src="image"
            :alt="productName"
            loading="lazy"
            class="product-card__image"
            :class="{ 'product-card__image-incoming': comingSoon }"
          />
        </div>
      </div>
      <div class="product-card__info">
        <div class="product-card__name">
          {{ productName }}
        </div>
        <div
          :class="['product-card__price',
            {'product-card__price-campaign': originalPrice}]"
          v-if="!hidePrices"
        >
          {{ $formatPrice(currentPrice) }}
          <div v-if="originalPrice" class="product-card__price-original">
            {{ $formatPrice(originalPrice) }}
          </div>
        </div>
        <div class="product-card__variants" v-if="!hideRelatedArticles">
          <div class="product-card__variant-container">
            <div
              class="product-card__variant"
              v-for="(variant, idx) in otherVariants"
              :key="idx"
            >
              <div class="product-card__articleNumber">
                {{ variant.articleNumber }}
              </div>
              <div class="product-card__variant-separator">,</div>
            </div>
          </div>
        </div>
        <div class="product-card__more-info" v-if="hasMoreInfo">
          <div class="product-card__print" v-if="printAvailable">
            {{ common_printavailable }}
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<style>
.product-card {
  cursor: pointer;
  text-decoration: none;
  background-color: white;
}

.product-card__content {
  margin: 0.25rem;
}

.product-card__image-container {
  overflow: hidden;
  position: relative;
  background-color: white;
}

.product-card__product-badge-container {
  z-index: 20;
  position: absolute;
  left: 10px;
  top: 10px;
}

.product-card__product-badge {
  font-size: 10px;
  text-transform: uppercase;
  color: #000000;
  padding: 6px 30px;
  background-color: var(--color-fade-green);
}

.product-card__product-incoming-container {
  z-index: 20;
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
}

.product-card__product-incoming {
  font-size: 10px;
  text-transform: uppercase;
  color: #000000;
  padding: 6px 30px;
  background-color: var(--color-fade-green);
  text-align: center;
}

.product-card__image-spacer {
  padding-top: 125%;
}

.product-card__image-overlay {
  z-index: 10;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.8;
  background-color: #efefef;
  mix-blend-mode: darken;
}

.product-card__image {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  margin: auto;
}

.product-card__image-incoming {
  opacity: 0.5;
}

.product-card__info {
  padding: 0.5rem 0;
  background-color: white;
}

.product-card__name,
.product-card__price {
  font-size: 14px;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 0.8px;
  line-height: 1.2rem;
  display: flex;
}

.product-card__name{
  height: 3.75rem;
}

.product-card__price-campaign {
  color: var(--color-feedback-error);
}

.product-card__brand-name {
  font-size: 10px;
  color: var(--color-gray-720);
  line-height: 3em;
  letter-spacing: 0.6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 30px;
}

.product-card__more-info {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0 0.5rem;
  color: #000000;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.7px;
}

.product-card__price-original {
  margin-left: 0.5rem;
  color: var(--color-text-darker);
  position: relative;
}

.product-card__price-original::after {
  content: "";
  position: absolute;
  top: 52%;
  left: -1px;
  right: -1px;
  border-top: 1px solid var(--color-text-darker);
}

.product-card__variants,
.product-card__variant-container,
.product-card__variant {
  display: flex;
}

.product-card__variant-container {
  flex-wrap: wrap;
}

.product-card__variant {
  margin-top: 0.35rem;
  padding-right: 0.25rem;
  margin-bottom: -0.5rem;
}

.product-card__articleNumber {
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.59px;
  color: #6d6d8e;
}

.product-card__variant-container
  .product-card__variant:last-child
  .product-card__variant-separator {
  display: none;
}

@media (--tabletAndDesktop) {
  .product-card__content {
    margin: 0.75rem 0.75rem 0;
  }

  .product-card__name {
    color: #1a1a1a;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.2rem;
    margin-top: 1rem;
    height: 2.4rem;
  }

  .product-card__price {
    color: #1a1a1a;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
  }

  .product-card__price-campaign {
    color: var(--color-feedback-error);
  }
}

@media (--desktop) {
  .product-card__content {
    background-color: var(--color-neutrals-60);
  }
}
</style>
