<script>
export default {
  inject: {
    $giftCardStateReactive: '$giftCardStateReactive',
  },
  computed: {
    backgroundImageUrl() {
      return this.$toLitiumMediaUrl(this.backgroundImage, { maxWidth: 925 });
    },
    backgroundImage() {
      return this.$giftCardStateReactive?.websiteImageSystemId || '';
    },
    logoTypeUrl() {
      return this.$toLitiumMediaUrl(this.logoType, { maxWidth: 135 });
    },
    logoType() {
      return this.$giftCardStateReactive?.webLogoTypeSystemId || '';
    },
    showImageProdutImages() {
      // TODO: Add in the future
      return false;
    },
    message() {
      return this.$giftCardStateReactive?.webGiftCardDescription
        || this.$globalTexts.giftcard__personal_message
        || 'giftcard__personal_message';
    },
    messageColor() {
      return this.$giftCardStateReactive?.websiteTextColorInverted
        ? 'var(--color-white)'
        : 'var(--color-black)';
    },
  },
  methods: {
    imagePath(index) {
      return this.$toLitiumMediaUrl(this.productImages[index], {
        maxWidth: 175,
      });
    },
  },
};
</script>

<template>
  <div class="web-preview">
    <div class="web-preview__background-container">
      <div class="web-preview__background-spacer"></div>
      <img
        v-if="backgroundImage"
        class="web-preview__background"
        :src="backgroundImageUrl"
      />
      <p class="web-preview__message">
        {{ message }}
      </p>
      <div class="web-preview__logotype-container">
        <div class="web-preview__logotype-spacer"></div>
        <div v-if="!logoType" class="web-preview__logotype-placeholder">
          {{ $globalTexts.giftcard__logotype || 'giftcard__logotype' }}
        </div>
        <img v-if="logoType" class="web-preview__logotype" :src="logoTypeUrl" />
      </div>
    </div>
    <div class="web-preview__products">
      <div class="web-preview__product" v-for="image in 4" :key="image">
        <div class="web-preview__product-spacer"></div>
        <img
          v-if="showImageProdutImages"
          class="web-preview__product-image"
          :src="imagePath(image)"
        />
        <div v-if="!showImageProdutImages" class="web-preview__image-missing"></div>
      </div>
    </div>
  </div>
</template>

<style>
.web-preview {
  background: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.web-preview__background-container {
  position: relative;
  background: var(--color-neutrals-45);
}

.web-preview__background-spacer {
  padding-top: 40%;
}

.web-preview__background,
.web-preview__logotype {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-position: center;
}

.web-preview__background {
  object-fit: cover;
}

.web-preview__logotype {
  object-fit: contain;
}

.web-preview__logotype-container {
  position: absolute;
  top: 15%;
  right: 8%;
  width: 150px;
  max-width: 100%;
  margin: auto auto 0;
}

.web-preview__logotype-spacer {
  padding-top: 50%;
}

.web-preview__logotype-placeholder {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  display: grid;
  place-content: center;
  overflow: hidden;
  background: rgba(255,255,255, 0.8);
}

.web-preview__logotype-placeholder::after {
  position: absolute;
  content: "";
  inset: -6px;
  border: 7px dashed var(--color-neutrals-80);
}

.web-preview__message {
  position: absolute;
  margin: 0;
  font-weight: 700;
  color: v-bind(messageColor);
  font-size: 18px;
  bottom: 15%;
  left: 8%;
  white-space: pre-wrap;
}

.web-preview__products {
  flex: 1;
  display: grid;
  padding: 67px 67px 0;
  gap: 19px;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 100%;
}

.web-preview__product {
  position: relative;
  height: fit-content;
  background: var(--color-neutrals-45);
}

.web-preview__product-spacer {
  padding-top: 135%;
}

.web-preview__product-image {
  object-fit: cover;
  object-position: center;
  position: absolute;
  inset: 0;
}

.web-preview__image-missing {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background: url("../../../static/icons/image_default.svg");
  background-repeat: no-repeat;
  background-position: center;
}

@media (--phoneAndTablet) {
  .web-preview__background-spacer {
    padding-top: 85%;
  }

  .web-preview__logotype-container {
    width: 100px;
    top: 5%;
    right: 5%;
  }

  .web-preview__products {
    grid-template-columns: repeat(2, 1fr);
    padding: 37px 0 0;
    gap: 10px;
  }
}
</style>
