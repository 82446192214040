import { defineReceiver, defineCommand } from '@drapejs/invoker';
import { gql } from 'graphql-request';
import { request, query, sales } from '@motillo/drapejs-litium';

export const userFields = gql`
  fragment UserFields on User {
    isAuthenticated
    companyName
    canRepresentAnyOrganization
    organization {
      organizationName
      organizationNo
      organizationId
      navCustomerNumber
      name
      address
      address2
      postCode
      city
      country
      currency
      discountGroup
      countries {
        value
        text
      }
      deliveryCountries {
        value
        text
      }
      shippingAddresses {
        no
        code
        systemId
        name
        address
        address2
        postCode
        city
        country
      }
      contacts {
        no
        code
        systemId
        firstName
        lastName
        email
        phone
      }
    }
    organizations {
      organizationName
      organizationId
    }
    person {
      firstName
      lastName
      email
      emailUnverified
      phone
    }
  }
`;

export const commands = {
  changeOrganization: defineCommand<{
    url: string;
    organizationId: string;
  }>('changeOrganization'),
  login: defineCommand<{
    username: string;
    password: string;
  }>('login'),
  logout: defineCommand<{}>('logout'),
  forgotPassword: defineCommand<{
    email: string;
  }>('forgotPassword'),
  validateResetPasswordCode: defineCommand<{
    resetPasswordCode: string;
  }>('validateResetPasswordCode'),
  resetPassword: defineCommand<{
    resetPasswordCode: string;
    password: string;
  }>('resetPassword'),
  fetchMyOrders: defineCommand<{
    url: string;
  }>('fetchMyOrders'),
  fetchMyOrderDetails: defineCommand<{
    url: string;
    orderNo: string;
  }>('fetchMyOrderDetails'),
  fetchMyInvoiceDetails: defineCommand<{
    url: string;
    invoiceNo: string;
  }>('fetchMyInvoiceDetails'),
  updatePerson: defineCommand<{
    url: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
  }>('updatePerson'),
  sendEmailVerification: defineCommand<{
    url: string;
    newEmail: string;
  }>('sendEmailVerification'),
  validateEmail: defineCommand<{
    url: string;
    code: string;
  }>('validateEmail'),
  changePassword: defineCommand<{
    url: string;
    oldPassword: string;
    newPassword: string;
  }>('changePassword'),
  changeChannel: defineCommand<{
    url: string;
    countryId: string;
    pageId: string;
  }>('changeChannel'),
  updateBillingAddress: defineCommand<{
    url: string;
    no: string;
    name: string;
    address: string;
    address2: string;
    postCode: string;
    city: string;
    country: string;
  }>('updateBillingAddress'),
  addOrUpdateShippingAddress: defineCommand<{
    url: string;
    no: string;
    code: string;
    systemId: string;
    name: string;
    address: string;
    address2: string;
    postCode: string;
    city: string;
    country: string;
  }>('addOrUpdateShippingAddress'),
  addOrUpdateContact: defineCommand<{
    url: string;
    no: string;
    code: string;
    systemId: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  }>('addOrUpdateContact'),
  searchOrganizations: defineCommand<{
    url: string;
    searchPhrase: string;
  }>('searchOrganizations'),  
};

export const receivers = {
  changeOrganization: defineReceiver(commands.changeOrganization, async function (command, data) {
    const result = await request(this.cache, query(gql`
      mutation changeOrganization($url: String!, $organizationId: Guid!) {
        session(url: $url) {
          changeOrganization(organizationId: $organizationId) {
            error
            cart {
              ...CartFields
            }
            user {
              ...UserFields
            }
          }
        }
      }
      ${userFields}
      `, ...sales.withCartFields()), {
      url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
      organizationId: command.organizationId,
    });

    await this.cache.setItem('__user', result.session.changeOrganization.user);
    await this.cache.setItem('__cart', result.session.changeOrganization.cart);

    return {
      error: result.session.changeOrganization.error,
    };
  }, 'litium'),
  login: defineReceiver(commands.login, async function (command, data) {
    const result = await request(this.cache, query(gql`
      mutation login($url: String!, $username: String!, $password: String!) {
        session(url: $url) {
          login(username: $username, password: $password) {
            error
            cart {
              ...CartFields
            }
            user {
              ...UserFields
            }
            redirectUrl
          }
        }
      }
      ${userFields}
      `, ...sales.withCartFields()), {
      url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
      username: command.username,
      password: command.password,
    });

    const { error, user, cart } = result.session.login;

    if (error === 'NONE') {
      await this.cache.setItem('__cart', cart);
    }

    await this.cache.setItem('__user', user);

    return result.session.login;
  }, 'litium'),
  logout: defineReceiver(commands.logout, async function (command, data) {
    const result = await request(this.cache, query(gql`
      mutation logout($url: String!) {
        session(url: $url) {
          logout {
            error
            cart {
              ...CartFields
            }
            user {
              ...UserFields
            }
            redirectUrl
          }
        }
      }
      ${userFields}
      `, ...sales.withCartFields()), {
      url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
    });

    const { error, user, cart } = result.session.logout;

    if (error === 'NONE') {
      await this.cache.setItem('__cart', cart);
    }

    await this.cache.setItem('__user', user);

    return result.session.logout;
  }, 'litium'),
  forgotPassword: defineReceiver(commands.forgotPassword, async function (command, data) {
    const result = await request(this.cache, query(gql`
      mutation forgotPassword($url: String!, $email: String!) {
        session(url: $url) {
          forgot(email: $email) {
            error
            redirectUrl
          }
        }
      }`), {
      url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
      email: command.email,
    });

    return result.session.forgot;
  }, 'litium'),
  validateResetPasswordCode: defineReceiver(commands.validateResetPasswordCode, async function (command, data) {
    const result = await request(this.cache, query(gql`
      mutation resetPassword($url: String!, $resetPasswordCode: String!) {
        session(url: $url) {
          validateResetPasswordCode(resetPasswordCode: $resetPasswordCode)
        }
      }`), {
      url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
      resetPasswordCode: command.resetPasswordCode,
    });

    return result.session.validateResetPasswordCode;
  }, 'litium'),
  resetPassword: defineReceiver(commands.resetPassword, async function (command, data) {
    const result = await request(this.cache, query(gql`
      mutation resetPassword($url: String!, $password: String!, $resetPasswordCode: String!) {
        session(url: $url) {
          reset(password: $password, resetPasswordCode: $resetPasswordCode) {
            error
            redirectUrl
            user {
              ...UserFields
            }
          }
        }
      }
      ${userFields}
      `), {
      url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
      password: command.password,
      resetPasswordCode: command.resetPasswordCode,
    });

    await this.cache.setItem('__user', result.session.reset.user);

    return result.session.reset;
  }, 'litium'),
  fetchMyOrders: defineReceiver(commands.fetchMyOrders, async function (command, data) {
    const result = await request(this.cache, query(gql`
      mutation fetchMyOrders($url: String!) {
        session(url: $url) {
          fetchMyOrders {
            number
            date
            amount
            currency
            isInvoice
          }
        }
      }`), {
      url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
    });
    return result.session.fetchMyOrders;
  }, 'litium'),
  fetchMyOrderDetails: defineReceiver(commands.fetchMyOrderDetails, async function (command, data) {
    const result = await request(this.cache, query(gql`
      mutation fetchMyOrderDetails($url: String!, $orderNo: String) {
        session(url: $url) {
          fetchMyOrderDetails(orderNo: $orderNo) {
            unauthorized
            no
            date
            currency
            customer {
              name
              name2
              address
              address2
              postCode
              city
              country
            }
            shipping {
              date
              agent
              name
              name2
              address
              address2
              postCode
              city
              country
              contactName
              contactPhone
              contactEmail
            }
            rows {
              lineNumber
              sku
              imageSystemId
              description
              description2
              quantity
              price
              vat
            }
            price
            vat
          }
        }
      }`), {
      url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
      orderNo: command.orderNo,
    });
    return result.session.fetchMyOrderDetails;
  }, 'litium'),
  fetchMyInvoiceDetails: defineReceiver(commands.fetchMyInvoiceDetails, async function (command, data) {
    const result = await request(this.cache, query(gql`
      mutation fetchMyInvoiceDetails($url: String!, $invoiceNo: String) {
        session(url: $url) {
          fetchMyInvoiceDetails(invoiceNo: $invoiceNo) {
            unauthorized
            no
            date
            currency
            customer {
              name
              name2
              address
              address2
              postCode
              city
              country
            }
            shipping {
              date
              agent
              name
              name2
              address
              address2
              postCode
              city
              country
              contactName
              contactPhone
              contactEmail
            }
            rows {
              lineNumber
              sku
              imageSystemId
              description
              description2
              quantity
              price
              vat
            }
            price
            vat
          }
        }
      }`), {
      url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
      invoiceNo: command.invoiceNo,
    });
    return result.session.fetchMyInvoiceDetails;
  }, 'litium'),
  updatePerson: defineReceiver(commands.updatePerson, async function (command, data) {
    const result = await request(this.cache, query(gql`
      mutation updatePerson($url: String!, $firstName: String!, $lastName: String!, $phoneNumber: String!) {
        session(url: $url) {
          updatePerson(firstName: $firstName, lastName: $lastName, phoneNumber: $phoneNumber) {
            error
            user {
              ...UserFields
            }
          }
        }
      }
      ${userFields}
      `), {
      url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
      firstName: command.firstName,
      lastName: command.lastName,
      phoneNumber: command.phoneNumber,
    });

    await this.cache.setItem('__user', result.session.updatePerson.user);

    return result.session.updatePerson;
  }, 'litium'),
  sendEmailVerification: defineReceiver(commands.sendEmailVerification, async function (command, data) {
    const result = await request(this.cache, query(gql`
      mutation sendEmailVerification($url: String!, $newEmail: String!) {
        session(url: $url) {
          sendEmailVerification(newEmail: $newEmail) {
            error
            user {
              ...UserFields
            }
          }
        }
      }
      ${userFields}
      `), {
      url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
      newEmail: command.newEmail,
    });

    await this.cache.setItem('__user', result.session.sendEmailVerification.user);

    return result.session.sendEmailVerification;
  }, 'litium'),
  validateEmail: defineReceiver(commands.validateEmail, async function (command, data) {
    const result = await request(this.cache, query(gql`
      mutation validateEmail($url: String!, $code: String!) {
        session(url: $url) {
          validateEmail(code: $code){
            error
            user {
              ...UserFields
            }
          }
        }
      }
      ${userFields}
      `), {
      url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
      code: command.code,
    });

    await this.cache.setItem('__user', result.session.validateEmail.user);

    return result.session.validateEmail;
  }, 'litium'),
  changePassword: defineReceiver(commands.changePassword, async function (command, data) {
    const result = await request(this.cache, query(gql`
      mutation changePassword($url: String!, $oldPassword: String!, $newPassword: String!) {
        session(url: $url) {
          changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
        }
      }`), {
      url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
      oldPassword: command.oldPassword,
      newPassword: command.newPassword,
    });
    return { changePassword: result.session.changePassword };
  }, 'litium'),
  changeChannel: defineReceiver(commands.changeChannel, async function (command, data) {
    const result = await request(this.cache, query(gql`
      mutation setCountry($url: String!, $countryId: String!, $pageId: String!) {
        session(url: $url) {
          setCountry(countryId: $countryId, pageId: $pageId) {
            error
            redirectUrl
          }
        }
      }`), {
      url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
      countryId: command.countryId,
      pageId: command.pageId || '',
    });

    const { error } = result.session.setCountry;
    switch (error) {
      case 'NONE':
        return result.session.setCountry;
      case 'FAILED':
      default:
        throw 'Unknown error occurred';
    }
  }, 'litium'),
  updateBillingAddress: defineReceiver(commands.updateBillingAddress, async function (command, data) {
    const result = await request(this.cache, query(gql`
      mutation updateBillingAddress($url: String!, $no: String!, $name: String!, $address: String!, $address2: String!, $postCode: String!, $city: String!, $country: String!) {
        session(url: $url) {
          updateBillingAddress(no: $no, name: $name, address: $address, address2: $address2, postCode: $postCode, city: $city, country: $country) {
            error
            user {
              ...UserFields
            }
          }
        }
      }
      ${userFields}
      `), {
      url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
      no: command.no,
      name: command.name,
      address: command.address,
      address2: command.address2,
      postCode: command.postCode,
      city: command.city,
      country: command.country,
    });

    if (result.session.updateBillingAddress.error == 'NONE') {
      await this.cache.setItem('__user', result.session.updateBillingAddress.user);
    }

    return result.session.updateBillingAddress;
  }, 'litium'),
  addOrUpdateShippingAddress: defineReceiver(commands.addOrUpdateShippingAddress, async function (command, data) {
    const result = await request(this.cache, query(gql`
      mutation addOrUpdateShippingAddress($url: String!, $no: String!, $code: String!, $systemId: String!, $name: String!, $address: String!, $address2: String!, $postCode: String!, $city: String!, $country: String!) {
        session(url: $url) {
          addOrUpdateShippingAddress(no: $no, code: $code, systemId: $systemId, name: $name, address: $address, address2: $address2, postCode: $postCode, city: $city, country: $country) {
            error
            user {
              ...UserFields
            }
          }
        }
      }
      ${userFields}
      `), {
      url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
      no: command.no,
      code: command.code,
      systemId: command.systemId,
      name: command.name,
      address: command.address,
      address2: command.address2,
      postCode: command.postCode,
      city: command.city,
      country: command.country,
    });

    if (result.session.addOrUpdateShippingAddress.error == 'NONE') {
      await this.cache.setItem('__user', result.session.addOrUpdateShippingAddress.user);
    }

    return result.session.addOrUpdateShippingAddress;
  }, 'litium'),
  addOrUpdateContact: defineReceiver(commands.addOrUpdateContact, async function (command, data) {
    const result = await request(this.cache, query(gql`
      mutation addOrUpdateContact($url: String!, $no: String!, $code: String!, $systemId: String!, $firstName: String!, $lastName: String!, $email: String!, $phone: String!) {
        session(url: $url) {
          addOrUpdateContact(no: $no, code: $code, systemId: $systemId, firstName: $firstName, lastName: $lastName, email: $email, phone: $phone) {
            error
            user {
              ...UserFields
            }
          }
        }
      }
      ${userFields}
      `), {
      url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
      no: command.no,
      code: command.code,
      systemId: command.systemId,
      firstName: command.firstName,
      lastName: command.lastName,
      email: command.email,
      phone: command.phone,
    });

    if (result.session.addOrUpdateContact.error === 'NONE') {
      await this.cache.setItem('__user', result.session.addOrUpdateContact.user);
    }

    return result.session.addOrUpdateContact;
  }, 'litium'),
  searchOrganizations: defineReceiver(commands.searchOrganizations, async function (command, data) {
    const result = await request(this.cache, query(gql`
      query searchOrganizations($url: String!, $searchPhrase: String) {
        session(url: $url) {
          user {
            organizations(searchPhrase: $searchPhrase) {
              organizationName
              organizationId
            }            
          }
        }
      }`),{
      url: command.url,
      searchPhrase: command.searchPhrase,
    });
    return {
      organizations: result.session.user.organizations
    };
  }, 'litium'),  
};
