<script>
import ErrorMessage from './ErrorMessage';
import ArrowExpandable from '../ArrowExpandable';
import TextInput from './TextInput';

export default {
  components: {
    ArrowExpandable,
    ErrorMessage,
    TextInput,
  },
  props: {
    id: {
      type: String,
    },
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
    searchPlaceholder: {
      type: String,
      default: null,
    },
    label: {
      type: String,
    },
    height: {
      type: [Number, String],
      default: 'auto',
    },
    modelValue: {
      type: [String, Number],
    },
    errorMessage: {
      type: String,
    },
    addOption: {
      type: Boolean,
    },
    shrinkOnSelect: {
      type: Boolean,
      default: true,
    },
    enableSearch: {
      type: Boolean,
      default: false,
    },
    searchHandler: {
      type: Function,
    },
    searchFields: {
      type: Array,
    },
  },
  data() {
    return {
      isExpanded: false,
      hasFocus: false,
      focusedIndex: -1,
      searchPhrase: '',
      availableOptions: [],
      dropdownStyle: {},
    };
  },
  watch: {
    isExpanded(isExpanded) {
      this.searchPhrase = '';
      this.buildDropdownStyle();

      if (isExpanded) {
        this.$nextTick(() => {
            this.$refs?.searchInput?.focus();
        });
      }
    },
    async searchPhrase(value) {
      if (!value) {
        this.availableOptions = this.options;
      }

      const searchPhrase = value.toLowerCase();
      if (this.searchHandler) {
        await this.searchHandler(searchPhrase);
        this.availableOptions = this.options;
      } else {
        this.availableOptions = (this.options || []).filter((f) => {
          if (!this.searchFields) {
            return f.value && f.text.toLowerCase().includes(searchPhrase);
          }
          return f.value && this.searchFields.some((s) => f[s]?.toLowerCase().includes(searchPhrase));
        });
      }
    },
    options: {
      handler() {
        this.availableOptions = [...(this.options || [])];
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    optionsLookup() {
      return this.availableOptions.reduce((p, c) => {
        p[c.value] = c;
        return p;
      }, {});
    },
    selected() {
      if (this.modelValue) {
        return this.getOptionObject(this.modelValue);
      }
      if (!this.modelValue && !!this.placeholder) {
        return {
          value: null,
          text: this.placeholder,
        };
      }
      if (this.options && this.options.length > 0) {
        return this.options[0];
      }
      return {};
    },
    showPlaceholder() {
      return !this.modelValue && !!this.placeholder;
    },
    isActive() {
      return this.hasFocus && this.isExpanded;
    },
  },
  methods: {
    enter(element) {
      element.style.visibility = 'hidden';
      element.style.height = 'auto';

      element.style.visibility = null;
      element.style.height = this.height;

      setTimeout(() => {
        element.style.height = this.height;
      });
    },
    leave(element) {
      setTimeout(() => {
        element.style.height = this.height;
      });
    },
    selectOption(option) {
      if (!option) return;
      this.$emit('update:modelValue', option.value);
      this.$emit('change', option);

      if (this.shrinkOnSelect) {
        this.isExpanded = false;
      }
    },
    shrink() {
      this.isExpanded = false;
    },
    open() {
      this.hasFocus = true;
      this.isExpanded = true;
    },
    isSelected(value) {
      return this.selected.value === value;
    },
    isFocused(index) {
      return this.focusedIndex === index;
    },
    getOptionObject(optionValue) {
      return (
        this.optionsLookup[optionValue] || {
          value: null,
          text: null,
        }
      );
    },
    scrollToFocusedElement() {
      if (!this.isExpanded) return;
      if (!this.$refs.dropdown) return;

      const elementHeight = 37;
      this.$refs.dropdown.scrollTo(0, this.focusedIndex * elementHeight);
    },
    checkKeypress(key) {
      if (!this.hasFocus) return;
      switch (key.key) {
        case 'ArrowDown':
          key.preventDefault();
          this.isExpanded = true;

          this.focusedIndex += 1;
          this.focusedIndex %= this.options.length;
          this.scrollToFocusedElement();
          break;
        case 'ArrowUp':
          key.preventDefault();

          if (this.isActive) {
            this.focusedIndex = this.focusedIndex > 0 ? this.focusedIndex - 1 : this.options.length - 1;
            this.focusedIndex %= this.options.length;
            this.scrollToFocusedElement();
          }
          break;
        case 'Enter':
          if (this.isActive) {
            this.selectOption(this.options[this.focusedIndex]);
          }
          break;
        case 'Escape':
          this.isExpanded = false;
          break;
        default:
          const matchingOptions = this.options
            .map((o, i) => ({ text: o.text, index: i }))
            .filter((o) => o.text?.toLowerCase()?.startsWith(key.key?.toLowerCase()))
            .map((o) => o.index);

          if (matchingOptions?.length) {
            this.isExpanded = true;
            const resetIndex = this.focusedIndex === matchingOptions[matchingOptions.length - 1]
                || this.focusedIndex === -1
                || !this.options[this.focusedIndex].text.toLowerCase().startsWith(key.key.toLowerCase());

            this.focusedIndex = resetIndex ? matchingOptions[0] : matchingOptions.find((c) => c > this.focusedIndex);
            this.scrollToFocusedElement();
          }
      }
    },
    hoverOption(index) {
      this.focusedIndex = index;
    },
    resetFocused() {
      this.focusedIndex = -1;
    },
    onClick($event) {
      if (!this.$refs.dropdownEl.contains($event.target) && this.isExpanded) {
        this.hasFocus = false;
        this.isExpanded = false;

        this.$emit('blur');
      }
    },
    buildDropdownStyle() {
      if (!this.isExpanded) return;
      const rect = this.$refs.dropdownInput?.getBoundingClientRect();
        if (rect) {
          const distanceToBottom = window.innerHeight - rect.bottom;
          this.dropdownStyle.maxHeight = `${Math.max(300,distanceToBottom) - 60}px`;
          if(distanceToBottom < 300){
            this.dropdownStyle.bottom = `${rect.height}px`;
          }else{
            
            this.dropdownStyle.bottom = ``;
          }
        }
    },
  },
  mounted() {
    window.addEventListener('click', this.onClick);
    window.addEventListener('resize', this.buildDropdownStyle);

    // this.availableOptions = this.options || [];
  },
  beforeUnmount() {
    window.removeEventListener('click', this.onClick);
    window.addEventListener('resize', this.buildDropdownStyle);
  },
};
</script>

<template>
  <div
    class="dropdown"
    tabindex="0"
    @mouseleave="resetFocused"
    @focus="
      hasFocus = true;
      $emit('focus');
    "
    @keydown="checkKeypress"
    @click="onClick($event)"
    ref="dropdownEl">
    <label
      v-if="label"
      :for="id"
      >{{ label }}</label
    >
    <div
      :id="id"
      class="dropdown_container"
      :class="{ 'dropdown--error': errorMessage }"
      ref="dropdownInput">
      <div
        v-if="availableOptions.length > 0 || placeholder"
        class="dropdown__placeholder"
        :class="{ 'dropdown__selected-value': !showPlaceholder }"
        @click="isExpanded = !isExpanded">
        <slot
          name="selected"
          :placeholder="placeholder"
          :selected="selected"
          >{{ showPlaceholder ? placeholder : selected.text }}</slot
        >
        <arrow-expandable :expanded="isExpanded" />
      </div>
      <transition
        name="expand"
        @enter="enter"
        @leave="leave">
        <div
          v-show="isExpanded"
          ref="dropdown"
          class="dropdown__select"
          :style="dropdownStyle">
          <div
            v-if="enableSearch"
            class="dropdown__search">
            <text-input
              :placeholder="searchPlaceholder"
              v-model="searchPhrase"
              ref="searchInput" />
            <img
              class="dropdown__search-input-icon"
              src="../../static/icons/search_icon.svg" />
          </div>
          <div
            class="dropdown__new-option"
            @click="$emit('add-option', $event)"
            tabindex="-1"
            v-if="addOption">
            <slot
              name="addOption"
              v-if="addOption">
              <img
                class="dropdown__new-option-icon"
                src="../../static/icons/plus_icon.svg" />
              <div class="dropdown__new-option-caption">{{ $globalTexts.global__add_new }}</div>
            </slot>
          </div>
          <div
            v-for="(option, index) in availableOptions"
            :key="index"
            :value="option.value"
            class="dropdown__option"
            :class="[
              isSelected(option.value) ? 'dropdown__option--selected' : '',
              isFocused(index) ? 'dropdown__option--hover' : '',
            ]"
            @click="selectOption(option)"
            @mousemove="hoverOption(index)">
            <slot
              name="option"
              :option="option"
              :isSelected="isSelected(option.value)"
              >{{ option.text }}</slot
            >
          </div>
        </div>
      </transition>
    </div>

    <error-message :message="errorMessage" />
  </div>
</template>

<style>
  .dropdown {
    width: 100%;
    position: relative;
    cursor: pointer;
    user-select: none;
  }

  .dropdown__search {
    padding: 0.5rem;
  }

  .dropdown__search-input-icon {
    position: absolute;
    top: 18px;
    right: 16px;
    width: 20px;
    height: 20px;
  }

  .dropdown_container {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .dropdown--error {
    border-color: var(--color-torch-red);
    color: var(--color-torch-red);
    transition: color 0.2s ease, border-color 0.2s ease;
  }

  .dropdown__select {
    width: 100%;
    z-index: 1001;
    position: absolute;
    background-color: #fff;
    border: none;
    overflow-x: hidden;
    border: 1px solid var(--color-border-50);
    max-height: 50vh;
  }

  .dropdown__select:focus {
    outline: none;
    outline-offset: 0;
    overflow: auto;
  }

  .dropdown__option {
    padding: 10px 13px;
    cursor: pointer;
  }

  .dropdown__new-option {
    display: flex;
    flex-direction: row;
    align-content: center;
    margin: 0 13px;
    cursor: pointer;
  }

  .dropdown__new-option-icon {
    width: 16px;
    height: 16px;
    margin: 0;
    padding-top: 3px;
    padding-right: 2px;
  }

  .dropdown__new-option-caption {
    padding-left: 0.5rem;
  }

  .dropdown__option:hover {
    text-decoration: underline;
  }

  .dropdown__new-option:first-child,
  .dropdown__option:first-child {
    padding-top: 20px;
  }

  .dropdown__new-option:last-child,
  .dropdown__option:last-child {
    padding-bottom: 20px;
  }

  .dropdown__option--selected,
  .dropdown__selected-value {
    font-weight: 700;
  }

  .dropdown__selected-value {
    line-height: 40px;
  }

  .dropdown__placeholder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 0 13px;
    line-height: 40px;
    border-color: var(--color-border-50);
    background-color: #ffffff;
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
  }

  .dropdown__contracted {
    border-radius: 4px;
  }

  .expand-enter-active,
  .expand-leave-active {
    transition: height 80ms linear;
    overflow: hidden;
  }

  .expand-enter,
  .expand-leave-to {
    height: 0;
  }
</style>
